import React from 'react'
import Hero from './hero/Hero'
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Testamonial from './testamonial/Testamonial'
import { Typography, Grid, Button, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
// import { Link as RouterLink }  from 'react-router-dom';

const photoDetails = () => ({
	hiRes: '/images/adjuster-3.png',
	lowRes: '/images/adjuster-6-m.jpg',
	alt: 'stop wondering where your next assignment will come from, start enjoying the success you deserve. You’ve earned it.'
})

const adjusterStyles = makeStyles(theme => ({
	root: {
		fontWeight: 600, 
		color: '#323b4d', 
		// background: '#efefef',
		// marginTop: '12em',
		[theme.breakpoints.up('md')]: {
			marginTop: 0,
		}
	}
}))

export default function Adjusters(props) {
	const classes = adjusterStyles();
	const photo = photoDetails();
	const isMobile = useMediaQuery('(max-width:700px)');


	const rosterList = () => ([
		{ link: '/', title: 'Register and apply.', text: 'Our online form makes it easy to start the process. We’ll learn more about your expertise and customer-service experience, and we’ll tailor our assignments to your experience and preferred service areas.'},
		{ link: '/', title: 'Set your availability.', text: 'Once you\'re approved for our roster, you can contact dispatch directly via email, phone, or text to set your availability and schedule. Then, we’ll put you into the claim rotation'},
		{ link: '/', title: 'Get paid.', text: 'Claim Consultant Group is known for its dedication to its field adjusters, and we show our appreciation by rewarding our adjusters where it counts most – their bank accounts.'},
	])

	// const testamonialProps = () => {
	// 	return {
	// 		name: 'Ryan Vickery',
	// 		company: 'CCG Adjuster',
	// 		photoLink: '/images/vickery.jpg',
	// 		title: 'Everybody’s treated with respect and loyalty',
	// 		rating: 5,
	// 	}
	// }

	const heroProps = () => ({
		// text: 'We treat every policyholder with care and respect.',
		text: 'Join Our Team',
		subText: 'As an adjuster, you’re diligent, organized, self-motivated, and most important, you’re a customer service pro.',
		// photo: photo.hiRes,
		mobilePhoto: photo.lowRes,
		backgroundColor: '#e5e7eb'
	})

	const CallToAction = () => (
		<React.Fragment>
			<Button variant="contained" color="primary" size="large" component={'a'} href="https://hr.ccgops.net/register" style={{ fontWeight: 900,  }} >Apply Now</Button>
			<Button variant="contained" color="default" size="large" component={'a'} href="https://hr.ccgops.net/login" style={{  }} >Roster Login</Button>
			<Button variant="contained" color="default" size="large" component={'a'} href="https://secure.goco.io/login" style={{  }} >HR Login</Button>			
		</React.Fragment>
	)
	
	return (<>
		<div style={{ background: '#f4f4f4' }}>
		<Hero callToAction={<CallToAction />} {...heroProps() } /> 

		<div style={{padding: 24}} className={classes.root}>
			<Grid container spacing={4} style={{ display: 'flex', justifyContent: 'center' }}>
				<Grid item xs={12} md={8} xl={8}>
					<Typography variant="h2" align='center' style={{ paddingTop: '2rem', paddingBottom:'2rem', fontWeight: 900 }}>We've Got Your Back.</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4} style={{ display: 'flex', justifyContent: 'center' }}>
				<Grid item xs={12} md={4} xl={4}>
					<Typography variant='h6' align='left'>
						We know you work hard, and you deserve full support from an IA firm that can provide the updated technology you need to succeed, and will make sure you’re compensated generously for your expertise. (And that you receive your payments quickly!)
					</Typography>
				</Grid>
				<Grid item xs={12} md={4} xl={4}>
					<Typography variant='h6' align='left'>
						We understand what it’s like to be in the field, and we’ve invested in the technology and a quality support team to make sure you’re able to do what you do best – support our carriers’ policyholders during some of the most stressful times of their lives.
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4} style={{ display: 'flex', justifyContent: 'center', paddingTop: '4em', }}>
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant='h4' align='center' style={{ fontWeight: 900, color: '#323b4d' }}>
						Interested in joining our roster? Do this:
					</Typography>
				</Grid>
				<Grid item xs={12} md={8} xl={7} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '4em', }}>
					<List component="ul" aria-label="list">
		    	{	rosterList().map((item, index) => (
		    		<React.Fragment key={index}>
		    			<ListItem className={classes.listItem}>
		    			{  !isMobile 
		    				? <ListItemIcon>
		    						<ArrowForwardIos />
		    					</ListItemIcon> 
	    					: null
		    			}
			          <ListItemText>
			          <Typography variant='h6' className={classes.listText} style={{ fontWeight: 900 }}>
										{ item.title }  
									</Typography>
			          	<Typography variant='h6' className={classes.listText}>
										{ item.text }  
									</Typography>
			          </ListItemText>
			        </ListItem>
			        <Divider light={true} className={classes.divider} />
			      </React.Fragment>
		    		)) }
		     </List>
				</Grid>
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '4em' }}>
					<Button component={'a'} href="https://hr.ccgops.net" variant='contained' size="large" color="secondary" style={{ background: '#323b4d' }}>
						<span style={{ fontWeight: 900, color: '#f7f7f7' }}>Apply Now</span>
					</Button>
				</Grid>
			</Grid>
		</div>

		{/*<Testamonial { ...testamonialProps() }>
			"As an adjuster, it can feel like there are a million obstacles in your career path. For example, with some independent claims businesses, you get treated like a number. And, it can take several weeks – or even months – to get paid. But with CCG, because they know what it’s like to work in the field, everybody’s treated with respect and loyalty. We get to work with great clients nationwide and we feel like we’re part of a team. That’s a breath of fresh air in the insurance industry."
		</Testamonial>*/}
		</div>
	</>)
}